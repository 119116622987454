import { docReady } from "./utils";
import detectorInit from "./detector";
import tooltipInit from "./tooltip";
import progressBar from "./progress-bar";
import popoverInit from "./popover";
import toastInit from "./toast";
import navbarInit from "./navbar-darken-on-scroll";
import plyrInit from "./plyr";
import initMap from "./googleMap";
import countupInit from "./countup";
import swiperInit from "./swiper";
import isotopeInit from "./isotope";
import typedTextInit from "./typed";
import formInit from "./form-processor";
import rellaxInit from "./rellax";
import bigPictureInit from "./bigPicture";
import dropdownOnHover from "./dropdown-on-hover";
import progressAnimationToggle from "./progress";
import dropdownMenuInit from "./dropdown-menu";
import glightboxInit from "./lightbox";
import bgPlayerInit from "./bg-player";
import zanimationInit from "./zanimation";
import thumbnailgridInit from "./thumbnail-grid";
import hoverDirInit from "./hover-dir";
import testimonialSwiperInit from "./testimonial";
import BackToTopInit from "./back-to-top";
import preloaderInit from "./preloader";

/* -------------------------------------------------------------------------- */
/*                            Theme Initialization                            */
/* -------------------------------------------------------------------------- */
docReady(detectorInit);
docReady(tooltipInit);
docReady(progressBar);
docReady(popoverInit);
docReady(toastInit);
docReady(navbarInit);
docReady(plyrInit);
docReady(initMap);
docReady(countupInit);
docReady(progressAnimationToggle);
docReady(swiperInit);
docReady(isotopeInit);
docReady(typedTextInit);
docReady(formInit);
docReady(rellaxInit);
docReady(bigPictureInit);
docReady(dropdownOnHover);
docReady(dropdownMenuInit);
docReady(glightboxInit);
docReady(bgPlayerInit);
docReady(zanimationInit);
docReady(hoverDirInit);
docReady(thumbnailgridInit);
docReady(testimonialSwiperInit);
docReady(BackToTopInit);
docReady(preloaderInit);
